import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isScullyRunning, TransferStateService } from '@scullyio/ng-lib';
import { catchError, combineLatest, EMPTY, map, Observable, of, retry, switchMap, tap } from 'rxjs';
import { Converter } from 'showdown';

import config from '../../../config';
import { LoginModalContent, SocialMediaLink } from '../models';
import { FormConfigContent } from '../models/FormConfigContent';
import { DevToolContent, DevToolsPageContent } from '../../templates/dev-tools/dev-tools.model';
import { LanguageEnum, StrapiFilterOperator } from '../enums';
import { CloudCalculatorContent } from '../../widgets/cloud-calculator/cloud-calculator.model';
import { StreamingCalculatorLabels } from '../../pages/streaming-platform-calculator/streaming-platform-calculator.model';
import { mapAttributes, mapToStringsArray, splitArrayToChunks } from '../utils';
import { MapService } from './map.service';
import { LocalizationService } from './localization.service';
import { SeoService } from './seo.service';

type PublicationState = 'preview' | 'live';

interface StrapiCollectionResponse<T> {
    data: Array<{
        attributes: T;
    }>;
}

interface StrapiSingleResponse<T> {
    data: {
        attributes: T;
    };
}

interface StrapiQueryParams extends Record<string, string> {
    locale?: LanguageEnum;
    publicationState?: PublicationState;
    populate?: 'deep';
}

@Injectable({
    providedIn: 'root',
})
export class StrapiContentService {
    private publicationState: PublicationState = (config.preview as PublicationState) || 'live';

    constructor(
        private http: HttpClient,
        private transferState: TransferStateService,
        private localizationService: LocalizationService,
        private mapService: MapService,
        private seoService: SeoService,
    ) {}

    public getPageFromCollection<T = any>(
        collection: string,
        route: string,
        isDynamic: boolean = false,
    ): Observable<T> {
        return this.getItemFromCollection<T>(collection, route, 'route');
    }

    public getItemFromCollection<T = any>(collection: string, key: string, keyName: string = 'key'): Observable<T> {
        const keyFilter = `filters[${keyName}][$eq]`;
        const request = this.getRequest<StrapiCollectionResponse<T>>(collection, {
            locale: this.localizationService.getCurrentLocale(),
            populate: 'deep',
            publicationState: this.publicationState,
            [keyFilter]: key,
        }).pipe(
            map((response) => {
                const content = response?.data?.[0]?.attributes;
                if (!content) {
                    console.error(`"${key}" item of "${collection}" collection was not found`);
                    return EMPTY as T;
                }
                return content;
            }),
        );

        return this.transferState.useScullyTransferState(collection, request);
    }

    public getCollection<T>(collectionName: string, collectionSort: string): Observable<Array<T>> {
        const url = `${config.cmsUrl}/api/${collectionName}?populate=deep&sort[createdAt]=${collectionSort}`;
        return this.http.get<{ data: Array<T> }>(url).pipe(
            map((response) => response.data),
            catchError((error) => {
                console.error(`Error fetching ${collectionName}:`, error);
                return EMPTY;
            }),
        );
    }

    public getCollectionType<T = any>(
        path: string,
        stateFieldName: string,
        params?: HttpParams,
    ): Observable<Array<{ attributes: T }>> {
        return this.transferState.useScullyTransferState(
            stateFieldName,
            this.getRequest<StrapiCollectionResponse<T>>(
                path,
                {
                    locale: this.localizationService.getCurrentLocale(),
                    populate: 'deep',
                    publicationState: this.publicationState,
                },
                params,
            ).pipe(map((response: any) => response.data)),
        );
    }

    public getCachedCollection<T = any>(path: string, params?: HttpParams): Observable<Array<{ attributes: T }>> {
        return config.preview === 'preview'
            ? this.getRequest<StrapiCollectionResponse<T>>(
                  path,
                  {
                      locale: this.localizationService.getCurrentLocale(),
                      populate: 'deep',
                      publicationState: this.publicationState,
                  },
                  params,
              ).pipe(map((response: any) => response.data))
            : this.getInnerRequest<StrapiCollectionResponse<T>>(
                  `/assets/archives/${this.localizationService.getCurrentLocale()}-${path}.json`,
                  params,
              ).pipe(map((response: any) => response.data));
    }

    public getSingleContentType<T = any>(path: string, stateFieldName: string): Observable<T> {
        return this.transferState.useScullyTransferState(
            stateFieldName,
            this.getRequest<StrapiSingleResponse<T>>(path, {
                locale: this.localizationService.getCurrentLocale(),
                populate: 'deep',
                publicationState: this.publicationState,
            }).pipe(map((response) => response?.data.attributes)),
        );
    }

    public getAssetsFromLibrary(name: string): Observable<any> {
        const filter = 'filters[name][$eq]';
        return this.transferState.useScullyTransferState(
            'asset',
            this.getRequest('upload/files', {
                [filter]: name,
            }).pipe(
                map((response: any) => {
                    return response;
                }),
            ),
        );
    }

    public getLoginModalContent(): Observable<LoginModalContent> {
        return this.getSingleContentType('login-modal', 'loginModal').pipe(
            map((data: any) => ({
                ...data,
                edgeProductsList: data.edgeProductsList.map(mapToStringsArray),
                hostingProductsList: data.hostingProductsList.map(mapToStringsArray),
            })),
        );
    }

    public getMultipleLocationsWithRegions(): Observable<any> {
        return combineLatest([
            this.getLcationRegions(),
            this.getCloudLocations(),
            this.getCollectionType('network-locations', 'networkLocations'),
        ]).pipe(
            map(([locationRegions, cloudLocations, networkLocations]) => ({
                locationRegions,
                cloudLocations: cloudLocations.map(({ attributes }: any) =>
                    this.mapService.mapLocationToMarker(attributes),
                ),
                networkLocations: networkLocations.map(({ attributes }: any) =>
                    this.mapService.mapLocationToMarker(attributes),
                ),
            })),
        );
    }

    public getCloudCalculatorContent(): Observable<CloudCalculatorContent> {
        return this.getSingleContentType('cloud-calculator', 'cloudCalculator').pipe(map((data) => ({ ...data })));
    }

    public getStreamingPlatformCalculator(): Observable<StreamingCalculatorLabels> {
        return this.getSingleContentType('streaming-calculator', 'streamingCalculator').pipe(
            map((data: any) => ({
                ...data,
            })),
        );
    }

    public getDevToolsPage(): Observable<DevToolsPageContent> {
        const converter = new Converter();
        return this.getSingleContentType('dev-tools-page', 'devToolsPage').pipe(
            tap((data) => {
                this.seoService.setMetaInfo(data.metaData);
            }),
            map((data: DevToolsPageContent) => ({
                ...data,
                protectionText: converter.makeHtml(data.protectionText),
            })),
        );
    }

    public getDevTool(url: string): Observable<DevToolContent> {
        const params = new HttpParams().set(`filters[url][${StrapiFilterOperator.ContainsI}]`, url);
        const converter = new Converter();
        return combineLatest([
            this.getCollectionType('dev-tools', `devTool + ${url}`, params),
            this.getMetaData('dev-tools', params),
        ]).pipe(
            map(([data]) => {
                const content = data.map(mapAttributes)[0];
                this.seoService.setMetaInfo(content.metaData);
                return {
                    ...content,
                    inputList: Object.fromEntries(content.inputList.map((item: any) => [item.name, item])),
                    descriptionSection: content.descriptionSection.map((article: any) => ({
                        ...article,
                        description: converter.makeHtml(article.description),
                    })),
                };
            }),
        );
    }

    public getSocialMedia(): Observable<Array<SocialMediaLink>> {
        return this.getCollectionType('social-media-links', 'footer').pipe(
            map((content: any) => content.map(mapAttributes)),
        );
    }

    public getFormConfigContent(): Observable<FormConfigContent> {
        return this.getSingleContentType('form-config', 'formConfig');
    }

    public getMetaData<T = any>(path: string, params?: HttpParams): Observable<any> {
        return this.transferState.useScullyTransferState(
            'metaData',
            this.getRequest<T>(
                path,
                {
                    locale: this.localizationService.getCurrentLocale(),
                    populate: 'deep',
                },
                params,
            ).pipe(
                map((response: any) => {
                    if (params) return response?.data[0].attributes?.metaData;
                    return response?.data.attributes?.metaData;
                }),
            ),
        );
    }

    public getCloudLocations(params?: HttpParams): Observable<any> {
        return this.getCollectionType('cloud-locations', 'cloudLocations', params);
    }

    public getHostingLocations(): Observable<any> {
        return this.getCloudLocations().pipe(
            map((locations) =>
                locations.filter(({ attributes }: any) => {
                    const tags = attributes.tags.split('\n');
                    return tags.some((tag: string) => {
                        if (tag.includes('hosting')) {
                            if (tag.includes('planned')) {
                                attributes.type = 'planned';
                            }
                            return true;
                        }
                        return false;
                    });
                }),
            ),
        );
    }

    public getLcationRegions(): Observable<any> {
        return this.getCollectionType('location-regions', 'LocationRegion').pipe(
            map((data) =>
                data.map((item: any) => ({
                    value: item.attributes.value,
                    label: item.attributes.label,
                })),
            ),
        );
    }

    public getDatacenterLocations(): Observable<any> {
        return this.getCollectionType('datacenter-locations', 'datacenterLocations').pipe(
            map((data) => {
                return data.map((item: any) => ({
                    ...item.attributes,
                    country: item.attributes.country.replace('%20', ' '),
                }));
            }),
        );
    }

    public getHostingDataFromCustomLocation(locale: string): Observable<any> {
        return this.transferState.useScullyTransferState(
            'seoHostingPage',
            this.getRequest('seo-hosting-page', {
                locale: locale as LanguageEnum,
                populate: 'deep',
            }).pipe(map((response: any) => response?.data.attributes)),
        );
    }

    public mapHostingTabs(pageData: any, citiesList: any): any {
        return pageData.tabs.map((tab: any) => {
            const list = ['city', '城市', 'stadt', '도시', '都市'].includes(tab.name?.toLowerCase())
                ? citiesList
                : tab.list;
            const columnCount = 3;

            return {
                ...tab,
                lists: splitArrayToChunks(list, Math.ceil(list.length / columnCount)),
            };
        });
    }

    public mapFaqAccordionItems(pageData: any, converter: any): any {
        return pageData.faqAccordionItems.map((accordionItem: any) => {
            return {
                ...accordionItem,
                description: converter.makeHtml(accordionItem.description),
            };
        });
    }

    public getRoutes(language?: string): Observable<any> {
        const isPreview = config.preview === 'preview';
        const routeType = isPreview ? 'preview' : 'routes';
        const path = language ? `${routeType}/${language}` : 'root';
        if (isPreview || isScullyRunning()) {
            return this.getRequest(path, null, null, 'routing');
        }
        return this.getInnerRequest(`assets/${language || 'root'}-routes.json`).pipe(
            switchMap((response: any) => (response ? of(response) : this.getRequest(path, null, null, 'routing'))),
        );
    }

    private getRequest<T>(
        path: string,
        queryParams?: StrapiQueryParams,
        params?: HttpParams,
        plugin: string = 'api',
    ): Observable<T> {
        const query = this.toQueryParams(queryParams);
        const url = `${config.cmsUrl}/${plugin}/${path}${query}`;
        return this.http
            .get(url, {
                headers: { Authorization: `bearer ${config.cmsJWT}` },
                params,
            })
            .pipe(
                retry<any>(2),
                catchError((error) => {
                    console.error(error);
                    return of(null as unknown as T);
                }),
            );
    }

    private getInnerRequest<T>(path: string, queryParams?: any, params?: HttpParams): Observable<any> {
        const query = this.toQueryParams(queryParams);
        return this.http.get(`${path}${query}`, { params }).pipe(
            catchError((error) => {
                console.error(error);
                return of(null);
            }),
        );
    }

    private toQueryParams(queryParams: StrapiQueryParams): string {
        if (!queryParams) return '';
        return `?${Object.entries(queryParams)
            .map((pair) => pair.join('='))
            .join('&')}`;
    }
}
