<div class="gc-search-console gc-bg_white">
    <div class="gc-content-wrapper">
        <div class="gc-console-close-button-container">
            <button class="gc-close-button" (click)="closeConsole()">
                <svg-icon src="assets/icons/close.svg" />
            </button>
        </div>
        <div class="gc-search-bar gc-flex gc-gap_x-small">
            <button class="gc-search-button" [class.gc-button-disable]="isButtonDisable" (click)="goToSearchPage()">
                <svg-icon src="assets/icons/search.svg" />
            </button>

            <input
                type="search"
                #input
                [ngModel]="value"
                (ngModelChange)="onInput($event)"
                id="search"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="none"
                spellcheck="false"
                maxlength="60"
                (keydown)="goToSearchPage($event)"
                [placeholder]="content.inputPlaceholder"
                aria-label="Search bar"
                enterkeyhint="go"
            />
            <button class="gc-close-button" [class.gc-close-button_visible]="value?.length" (click)="clearInput()">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        id="Item"
                        d="M8 8L4 4M8 8L12 12M8 8L12 4M8 8L4 12"
                        stroke="#251B29"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
        </div>
        <div class="gc-search-content gc-flex gc-flex-h-space-between gc-p-top_x-large">
            <div class="gc-search-results" *ngIf="results$ | async as results">
                <p class="gc-text_14 gc-m-bottom_large" *ngIf="isNoResults">{{ content.noResultsTitle }}</p>
                <p class="gc-result-text gc-text_12">{{ resultText }}</p>
                <ul class="gc-list gc-m-top_large gc-flex gc-flex-column gc-gap_medium">
                    <li *ngFor="let hit of results" class="gc-flex gc-flex-v-center gc-gap_x-small">
                        <a class="gc-link gc-link_primary" [routerLink]="hit.route" (click)="closeConsole()">
                            <span
                                class="gc-link-text"
                                [innerHtml]="hit?._highlightResult?.menuTitle.value || hit.menuTitle || hit.metaTitle"
                            ></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="gc-search-back" (mouseenter)="closeConsole()"></div>
