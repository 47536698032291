<div
    class="gc-tag gc-tag_{{ iconPosition }} gc-display_inline-flex gc-gap_1 gc-border-radius_sm "
    [class.gc-border_brand]="type === 'brand'"
    [class.gc-border_primary]="type === 'neutral'"
    [class.gc-flex_row-reverse]="iconPosition === 'left'"
    [class.dark]="darkTheme"
>
    <span
        class="gc-label_xs"
        [class.gc-text_brand]="type === 'brand'"
        [class.gc-text_tertiary]="type === 'neutral'"
        [class.dark]="darkTheme"
        >{{ content.label }}</span
    >
    <svg-icon
        *ngIf="content.icon"
        [src]="ICONS_COLLECTION[content.icon]"
        class="gc-icon gc-icon_extra-small"
        [class.gc-icon_brand]="type === 'brand'"
        [class.gc-icon_disabled]="type === 'neutral'"
    />
</div>
