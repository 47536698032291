import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { ICONS_COLLECTION } from '../../../core/constants';
import { UrlParseDirective } from '../../../core/directives/url-parse/url-parse.directive';
import { ButtonIconContent } from './button-icon.model';

@Component({
    selector: 'gc-button-icon',
    standalone: true,
    imports: [CommonModule, SvgIconComponent, UrlParseDirective],
    templateUrl: './button-icon.component.html',
    styleUrls: ['./button-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonIconComponent {
    @Input() public darkTheme: boolean = false;
    @Input() public secondaryTheme: boolean = false;
    @Input() public size: 'sm' | 'md' | 'lg' = 'md';
    @Input() public type: 'primary' | 'secondary' | 'subtle' = 'primary';
    @Input() public state: 'none' | 'disabled' = 'none';
    @Input({ required: true }) public content: ButtonIconContent;
    @Output() public clickEvent = new EventEmitter<Event>();

    constructor(private renderer: Renderer2) {}

    public onClick(event: Event): void {
        this.clickEvent.emit(event);
    }

    protected readonly ICONS_COLLECTION = ICONS_COLLECTION;
}
